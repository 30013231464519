import React from 'react';

import { selectHasCategories, selectUserKycRequired, useWebAppSelector } from '@redux';
import { FeatureBlockSplashFeature } from 'components/FeatureBlockSplash/enums';
import { getBackgroundImageAltText, getBackgroundImageSrc } from 'components/FeatureBlockSplash/helpers';
import { useUserCapabilities } from 'hooks';

import KYCRequirement from '../KYCRequirement';
import NoCategories from '../NoCategories';
import PremiumCTA from '../PremiumCTA';

import styles from './FeatureBlockSplash.module.scss';

export interface FeatureBlockSplashProps {
  feature: FeatureBlockSplashFeature;
}

const FeatureBlockSplash: React.FC<FeatureBlockSplashProps> = ({ feature }) => {
  const { isUserStarter, isUserB2C } = useUserCapabilities();
  const isKycRequired = useWebAppSelector(selectUserKycRequired);
  const imageSrc = getBackgroundImageSrc(feature);
  const imageAltText = getBackgroundImageAltText(feature);
  const hasCategories = useWebAppSelector(selectHasCategories);

  const getContent = () => {
    if (isKycRequired) {
      return <KYCRequirement isUserB2C={isUserB2C} />;
    }

    if (!hasCategories) {
      return <NoCategories isUserB2C={isUserB2C} />;
    }

    if (isUserStarter) {
      return <PremiumCTA feature={feature} />;
    }

    return null;
  };

  return (
    <div className={styles.root}>
      {imageSrc && (
        <img
          className={styles.backgroundImage}
          src={imageSrc}
          alt={imageAltText}
        />
      )}
      <div className={styles.gradient} />
      <div className={styles.content}>{getContent()}</div>
    </div>
  );
};

export default FeatureBlockSplash;

import {
  PlanRenewalPeriod,
  PurchaseCreditCardAddRequest,
  PurchaseCreditCardAddResponse,
  PurchaseCreditCardAddReturn,
  PurchaseCreditCardDeleteRequest,
  PurchaseCreditCardDeleteResponse,
  PurchaseCreditCardDeleteReturn,
  PurchaseCreditCardSetAsDefaultRequest,
  PurchaseCreditCardSetAsDefaultResponse,
  PurchaseCreditCardSetAsDefaultReturn,
  PurchaseGetCheckoutConfirmationPageUrlRequest,
  PurchaseGetCheckoutConfirmationPageUrlResponse,
  PurchaseGetCheckoutConfirmationPageUrlReturn,
  PurchaseGetCheckoutPublicKeyResponse,
  PurchaseGetCheckoutPublicKeyReturn,
  PurchaseGetCreditCardsListResponse,
  PurchaseGetCreditCardsListReturn,
  PurchaseGetProductAvailabilitiesRequest,
  PurchaseGetProductAvailabilitiesResponse,
  PurchaseGetProductAvailabilitiesReturn,
  PurchaseGetProductPricesProps,
  PurchaseGetProductPricesReturn,
  PurchasePostSubmissionRequest,
  PurchasePostSubmissionResponse,
  PurchaseProductPricesRequest,
  PurchaseProductPricesResponse,
  PurchaseReceiptsListResponse,
  PurchaseReceiptsListResponseCategory,
  PurchaseReceiptsListResponseRaw,
  PurchaseReceiptsListResponseUserOffer,
  PurchaseService,
} from 'types';

import { purchaseReceiptsShouldShowSubscription } from 'helpers';
import { isString } from 'helpers/typeCheckers';

import { PURCHASE } from '@constants';
import { apiMobile, apiPurchase } from 'api';

const purchaseService: PurchaseService = {
  getCheckoutPublicKey: async (): Promise<PurchaseGetCheckoutPublicKeyReturn> => {
    try {
      const response = await apiPurchase.get<PurchaseGetCheckoutPublicKeyResponse>(
        '/authenticated/config/external-payment-provider',
        {},
      );

      const checkoutPublicKey = response?.checkoutPublicKey;

      if (!isString(checkoutPublicKey)) {
        throw new Error('Dev Error: API Error');
      }

      return {
        isFailure: false,
        checkoutPublicKey,
      };
    } catch {
      return {
        isFailure: true,
        checkoutPublicKey: '',
      };
    }
  },

  getCheckoutConfirmationPageUrl: async (args): Promise<PurchaseGetCheckoutConfirmationPageUrlReturn> => {
    try {
      const response = await apiPurchase.post<
        PurchaseGetCheckoutConfirmationPageUrlResponse,
        PurchaseGetCheckoutConfirmationPageUrlRequest
      >('/authenticated/credit-card/create-confirmation-url', args);

      const redirectConfirmationURL = response?.redirectConfirmationURL;

      if (!isString(redirectConfirmationURL)) {
        throw new Error('Dev Error: API Error');
      }

      return {
        isFailure: false,
        redirectConfirmationURL,
      };
    } catch {
      return {
        isFailure: true,
        redirectConfirmationURL: '',
      };
    }
  },

  fetchCreditCardsList: async (): Promise<PurchaseGetCreditCardsListReturn> => {
    try {
      const response = await apiPurchase.get<PurchaseGetCreditCardsListResponse>('/authenticated/credit-card/list', {});

      if (!Array.isArray(response?.creditCards)) {
        throw new Error('Dev Error: API Error');
      }

      return {
        isFailure: false,
        creditCards: response.creditCards,
      };
    } catch {
      return {
        isFailure: true,
        creditCards: [],
      };
    }
  },

  addCreditCard: async ({
    sid,
    isDefaultCreditCard = true,
  }: PurchaseCreditCardAddRequest): Promise<PurchaseCreditCardAddReturn> => {
    try {
      const response = await apiPurchase.post<PurchaseCreditCardAddResponse, PurchaseCreditCardAddRequest>(
        '/authenticated/credit-card/add-new',
        {
          sid,
          isDefaultCreditCard,
        },
      );

      const paymentMethodId = response?.paymentMethodId;

      if (!isString(paymentMethodId)) {
        throw new Error('Dev Error: API Error');
      }

      return {
        isFailure: false,
        paymentMethodId,
      };
    } catch {
      return {
        isFailure: true,
        paymentMethodId: '',
      };
    }
  },

  deleteCreditCard: async ({
    paymentMethodId,
  }: PurchaseCreditCardDeleteRequest): Promise<PurchaseCreditCardDeleteReturn> => {
    try {
      await apiPurchase.post<PurchaseCreditCardDeleteResponse, PurchaseCreditCardDeleteRequest>(
        '/authenticated/credit-card/delete-card',
        {
          paymentMethodId,
        },
      );

      return {
        isFailure: false,
      };
    } catch {
      return {
        isFailure: true,
      };
    }
  },

  setAsDefaultCreditCard: async ({
    paymentMethodId,
  }: PurchaseCreditCardSetAsDefaultRequest): Promise<PurchaseCreditCardSetAsDefaultReturn> => {
    try {
      await apiPurchase.post<PurchaseCreditCardSetAsDefaultResponse, PurchaseCreditCardSetAsDefaultRequest>(
        '/authenticated/credit-card/set-default-card',
        {
          paymentMethodId,
        },
      );

      return {
        isFailure: false,
      };
    } catch {
      return {
        isFailure: true,
      };
    }
  },

  fetchProductAvailabilities: async ({
    virtualPhoneNumber,
  }: PurchaseGetProductAvailabilitiesRequest): Promise<PurchaseGetProductAvailabilitiesReturn> => {
    try {
      const response = await apiMobile.post<
        PurchaseGetProductAvailabilitiesResponse,
        PurchaseGetProductAvailabilitiesRequest
      >('/plans/available', { virtualPhoneNumber });

      if (!Array.isArray(response?.plans)) {
        throw new Error('Dev Error: API Error');
      }

      const productIdMonthly = response.plans
        .flatMap((plan) => plan?.productIds?.find((productIds) => productIds.month === 1)?.productId)
        .filter((productId): productId is string => typeof productId === 'string');

      const productIdsAll = response.plans
        .flatMap((plan) => plan?.productIds?.flatMap((productIds) => productIds.productId))
        .filter((productId): productId is string => typeof productId === 'string');

      if (productIdMonthly.length === 0 || productIdsAll.length === 0) {
        throw new Error('Dev Error: API Success Data Error');
      }

      return {
        isFailure: false,
        productIdMonthly,
        productIdsAll,
      };
    } catch {
      return {
        isFailure: true,
        productIdMonthly: [],
        productIdsAll: [],
      };
    }
  },

  fetchProductPrices: async ({
    virtualPhoneNumber,
    locationCountryCode,
  }: PurchaseGetProductPricesProps): Promise<PurchaseGetProductPricesReturn> => {
    try {
      const productIdsResponse = await purchaseService.fetchProductAvailabilities({
        virtualPhoneNumber,
      });

      if (productIdsResponse.isFailure) {
        throw new Error('Dev Error: API Error "POST:/mobile/plans/available"');
      }

      const response = await apiPurchase.post<PurchaseProductPricesResponse, PurchaseProductPricesRequest>(
        '/authenticated/products/get-prices',
        {
          productIds: productIdsResponse.productIdsAll,
          locationCountryCode,
        },
      );

      if (!Array.isArray(response?.productPrices) || response.productPrices.length === 0) {
        throw new Error('Dev Error: API Error');
      }

      const productPricesWithMonthlyYearlyPrices = response.productPrices.map((productPrice) => {
        // TODO: Below parsing could be removed when https://onoffapp.atlassian.net/browse/SSW-12950 is done
        const matches = productPrice.productId.match(/\.(\d+)month\./);
        const month = Number(matches?.[1] ?? PURCHASE.DEFAULT_PLAN_PERIOD) as PlanRenewalPeriod;

        const monthlyPrice = productPrice.price / month;
        const yearlyPrice = (productPrice.price / month) * 12;

        return {
          ...productPrice,
          month,
          monthlyPrice,
          yearlyPrice,
        };
      });

      const productPricesSortedAscending = productPricesWithMonthlyYearlyPrices.sort(
        (priceA, priceB) => priceA.price - priceB.price,
      );

      return {
        isFailure: false,
        productPrices: productPricesSortedAscending,
      };
    } catch {
      return {
        isFailure: true,
        productPrices: [],
      };
    }
  },

  buyProductForExistingCategory: async ({
    virtualPhoneNumber,
    productId,
    categoryId,
    paymentMethodId,
  }: PurchasePostSubmissionRequest): Promise<void> => {
    await apiPurchase.post<PurchasePostSubmissionResponse, PurchasePostSubmissionRequest>(
      '/authenticated/credit-card/buy-product-for-existing-category',
      {
        virtualPhoneNumber,
        productId,
        categoryId,
        paymentMethodId,
      },
    );
  },

  getReceiptsList: async (): Promise<PurchaseReceiptsListResponse> => {
    const {
      categories: rawCategories = [],
      userOffer: { subscription: rawUserOfferSubscription, receipts: rawUserOfferReceipts } = {},
    } = await apiPurchase.get<PurchaseReceiptsListResponseRaw>('/authenticated/receipts/list', {});

    const categories: PurchaseReceiptsListResponseCategory[] = rawCategories
      .map((category) => ({
        virtualPhoneNumber: category.virtualPhoneNumber,
        receipts: category.receipts || [],
        subscription: category.subscription || {},
      }))
      .filter((category) => purchaseReceiptsShouldShowSubscription(category.subscription));

    const showUserOffer = rawUserOfferSubscription
      ? purchaseReceiptsShouldShowSubscription(rawUserOfferSubscription)
      : false;

    const userOffer: PurchaseReceiptsListResponseUserOffer = {
      subscription: showUserOffer ? rawUserOfferSubscription : undefined,
      receipts: showUserOffer ? rawUserOfferReceipts : undefined,
    };

    return {
      categories,
      userOffer,
    };
  },
};

export default purchaseService;
